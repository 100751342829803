/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import { Footer } from '@latitude/footer';
import { getUrlFromEnv } from '@/utils/getUrlFromEnvUtil';
import { Box } from '@latitude/box';
import { HeroText } from '@/components/HeroBranded/HeroBranded';
import Layout from '@/components/layout';
import MobileAppInstallPrompts from '@latitude/mobile-app-install-prompt/MobileAppInstallPrompt';
import {
  BREAKPOINT,
  COLOR,
  SOFT_QUOTE_LOANS_AU_URL
} from '../../../utils/constants';
import { Hero } from '@latitude/hero';
import Section from '@latitude/section';
import heroImage from "./images/tgg-logo-hero.webp";
import styled from 'styled-components';
import { InstalmentInterestFreeCalculatorAssignedMinimums } from '@latitude/instalment-interest-free-calculator/InstalmentInterestFreeCalculatorAssignedMinimums';
import { Link } from '@latitude/link';
import queryString from 'query-string';

import imageFeatureCardsSrc from './images/latitude-card-lockup.webp';

import footerData from '@/data/json/footer.json';
import PLData from '@/data/pages/interest-free/thegoodguys.json';

const TheGoodGuysLandingPage = props => {
  const applyUrlDefault = 'https://cardapp.latitudefinancial.com/apply/gem/?gemid2=lfs-web:information:interest-free:thegoodguys:apply-now:calculate-your-repayment';
  const applyUrlInstore = 'https://cardapp.latitudefinancial.com/apply/gem/api/Application/instore/gem?gemid2=lfs-web:information:interest-free:thegoodguys:apply-now:calculate-your-repayment&merchantID=000420130';
  let applyUrl = applyUrlDefault;

  let sourceId = null;
  let queryParams = null;
  if (typeof window !== 'undefined') {
    queryParams = queryString.parse(location.search);
    sourceId = queryParams && queryParams.source;
    if (sourceId) {
      if (sourceId == 'instore') {
        applyUrl = applyUrlInstore;
      };
    };
  };

  const SecondBar = styled.div`
    && {
      display: flex;
      background: rgb(255, 255, 255);
      box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 0px 0px;
      height: 48px !important;
      -webkit-box-pack: justify !important;
      justify-content: space-between !important;
      padding-top: 2px; 
      top: 80px;
      position: sticky;
      z-index: 3;

      @media (max-width: ${BREAKPOINT.LG}) {
        top: 56px;
      }
    }

    && > div {
      max-width: 1140px;
      display: flex;
      margin: auto;
      width: 1140px;
    }
  `;

  const CtaButton = styled(Link)`
    height: 40px;
    margin-top: 6px;
    margin-right: 12px;
    padding-left: 16px;
    padding-right: 16px;
    margin: auto 10px auto auto !important;
  `;

  return (
    <Layout
      location={props.location}
      
      metaDesc={PLData.metaDesc}
      title={PLData.title}
      canonical={props.location.href}
      noIndex
    >

      <main className="navigation-spacer">
        <SecondBar>
          <div class="secondbar__inner">
            <CtaButton
              className="mx-auto"
              button="primary"
              width="150px"
              href={applyUrl}
              target="_self"
              disableDefaultEventTracking
              disableDefaultFunnelTracking
            >
              Apply Now
            </CtaButton>
          </div>
        </SecondBar>
        <Box backgroundColor={COLOR.GREY6}>
          <MobileAppInstallPrompts />
          <Section
          css={`
              background-color: #0555C8;
              padding: 0;

              @media (max-width: ${BREAKPOINT.MD}) {
               && > .container.section__content {
                padding: 0;
               }

               div.HeroContent {
                 background-color: transparent;
                 color: #ffffff;
               }
              }
            `}
        >

          <Hero
            css={`
            && {
              color: #000;
            }

            h1 {
              color: #fff;
              font-size: 45px;
              font-style: normal;
              font-weight: 600;
              font-family: Montserrat;
              line-height: 3rem;
            }

            p {
              color: #fff;
              font-size: 19px;
              font-weight: normal;
            }

            svg {
              min-height: 45px;
            }

            .HeroContent {
              padding: 64px 0px 117px;
              line-height: normal;
            }

            [class^='Herostyles__HeroImage-'] {
              > div {
                display: flex;
                align-items: center;
              }

              img {
                height: 80%;
              }
            }

            .padding-right {
              padding-right: 3rem;
            }

            @media (max-width: ${BREAKPOINT.MD}) {
              [class^='Herostyles__HeroImage-'] {
                > div {
                  display: flex;
                  align-items: center;
                }

                img {
                  height: 60%;
                }
              }

              .padding-right {
                padding-right: 1rem;
              }

              p {
                font-size: 14px;
                line-height: 1.3rem;
              }
              
              h1 {
                font-size: 35px;
                margin: 5px 0;
              }

              .conditional-text{
                display:none;
              }

              .HeroContent {
                padding: 10px;
                
                &__insettext {
                  font-size: 12px;
                  a.button {
                    font-size: 18px;
                  }
                }
              }
              
              .text-center {
                color: #000!important;
              }
            }
          `}
            imageContent={
              <div
                css={`
                  height: 100%;
                  display: flex;
                  align-items: flex-end;
                  justify-content: center;
                  @media (min-width: ${BREAKPOINT.LG}) {
                    justify-content: flex-end;
                  }
                `}
              >
                <img
                  src={heroImage}
                  alt=""
                  css={`
                    height: 250px;
                    width: auto;
                    @media (min-width: ${BREAKPOINT.MD}) {
                      height: 300px;
                    }
                    @media (min-width: ${BREAKPOINT.LG}) {
                      height: 382px;
                    }
                  `}
                />
              </div>
              }
            
            className="page-banner-lfs--bg">

            {<div
              id="hero"
              css={`
                && {
                  padding: 0 15px;
                  color: #fff:
                  background-color: red;

                  @media (min-width: ${BREAKPOINT.LG}) {
                    padding: 0;
                  }

                  @media (max-width: ${BREAKPOINT.MD}) {
                    text-align: center;
                  }
                }
              `}
            >
              <h1>Interest Free Payment Plan Calculator</h1>
              <HeroText
                css={`
                  && {
                    color:#fff;
                    letter-spacing: -0.55px;
                    margin-right:0px;
                    font-size: 21px;

                    @media (max-width: ${BREAKPOINT.MD}) {
                      text-align: center;
                    }
                  }
              `}>
                Calculate payments for purchases on Equal Monthly payment plans using a participating Latitude credit card.
              </HeroText>
            </div>}
          </Hero>

          </Section>
          <Section 
            className="bg-lightest"
            css={`
              && {
                select, input {
                  background-color: #fff!important;
                }

                div.container.section__content > [class^='InstalmentInterestFreeCalculatorAssignedMinimums___StyledDiv'] > p[class^='css-1'] {
                  display: none;
                }
              }
            `}>
            <InstalmentInterestFreeCalculatorAssignedMinimums
              annualFee={0}
              establishmentFee={0}
              minimumAmount={600}
              monthlyFee={10.95}
              monthlyTerms={[
                {
                  duration: 24,
                  minFee: 600
                },
                {
                  duration: 36,
                  minFee: 700
                },
                {
                  duration: 50,
                  minFee: 750
                }
              ]}
              importantInfo={[
              ]}
              superscriptText={`<sup>#</sup>`}
            />
            <text>
               <text
                    css={`
                      && {
                        margin-bottom: 5px;
                        text-align: center;
                        font-weight: bold;
                        font-size: 26px;
                        display: block;                  
                      }
                    `}
                  >Important things to remember</text>
              <div class="container">
                <div class="row">
                 
                  <div class="col col-lg-8">
                    <p>This calculator is for Equal Monthly payment plans and does not take in to account any other purchases or balances.</p>
                    <p>The payment frequency amounts are approximate only. Refer to your statement for the exact amount.</p>
                    <p>Minimum spends apply. Refer to The Good Guys for current promotions.</p>
                    <p><sup>#</sup> The account service fee (card fee currently $10.95) is subject to change.</p>
                  </div>
                  <div class="col-lg-4 text-center text-lg-left">
                    <img src={imageFeatureCardsSrc} alt='Latitude credit cards' />
                    <p>Available on participating Latitude credit cards only. T&C’s, card fees, and other charges apply. Exclusions may apply. See in-store or online for details.</p>
                    <Link
                      button="primary"
                      css={`
                        && {
                          width: 200px; 
                          margin: 0 auto;
                        }
                      `}
                      href={applyUrl}
                      disableDefaultEventTracking
                      disableDefaultFunnelTracking
                    >
                      Apply now
                    </Link>
                  </div>
                </div>
              </div>              
            </text>
          </Section>
        </Box>
      </main>
    </Layout>
  );
};

export default TheGoodGuysLandingPage;
